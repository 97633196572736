<template>
    <q-layout>
        <!-- TOP / BRAND -->
        <!-- navbar -->
        <navbar></navbar>

        <!----------------------->
        <!-- PAGE SUB HEADER -->
        <!-- #1 -->
        <div v-if="id == 1">
            <div class="bg-cover bg-nature-drop-water bg-center">
                <div class="container mx-auto">
                    <div class="p-5 row">
                        <div class="col-12 flex flex-col items-start">
                            <h1 class="text-white text-3xl font-black font-epigrafica mt-2 uppercase ml-2">Le Masque antirides à la Carotte fait maison</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="container mx-auto">
                    <div class="py-14 bg-cover row">
                        <div class="container mx-auto">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <q-img class="img-smooth-zoom" fit="cover" ratio="1" src="@/assets/media/experts-counselings/ibc-international-bio-cosmetics-conseil-experts-masque-carotte.jpg" />
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-4xl font-black font-epigrafica mt-2 uppercase">Ce masque antirides maison à la Carotte va illuminer votre teint !</h1>
                                    <p class="text-mineralgreen text-base mt-3">
                                        La béta-carotène (qui lui donne sa couleur orange) et la vitamine C revitalise la peau et lui 
                                        donne bonne mine instantanément! Elles pulsent le teint, rebooste votre peau et lui donne du peps.
                                    </p>
                                    <p class="text-mineralgreen text-base mt-3">
                                        Choisissez bien vos légumes! Plus la carotte est orangée, plus elle renferme de beta carotène 
                                        qui est un précurseur de la vitamine A aux vertus hautement antioxydantes, donc anti-âge. 
                                        Il faut donc choisir ses légumes pour leur qualité, biologiques de préférence.
                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-2">Les ingrédients</h1>
                                    <p class="text-mineralgreen text-base">
                                        &middot; 1 demi carotte <br>
                                        &middot; 1/2 c. à soupe de miel <br>
                                        &middot; 1/2 c. à soupe d’huile d’olive <br>
                                        &middot; Quelques gouttes de jus de citron <br>
                                    </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 pr-5 pl-5 pt-5 pb-0">
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica">La Préparation</h1>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <p class="text-mineralgreen text-base">
                                        Faites cuire à la vapeur la carotte après l’avoir pelée. Écrasez là à la 
                                        fourchette et ajoutez les autres ingrédients à la purée. Laissez refroidir 
                                        et appliquez 20 minutes sur votre visage nettoyé.
                                    </p>
                                    <p class="text-mineralgreen text-base">
                                        Rincez à l’eau claire. Vous pouvez faire ce masque maison une fois par semaine.
                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <p class="text-mineralgreen text-base">
                                        Une petite variante de ce masque antirides. Bien souvent, lorsqu’on fait des 
                                        gâteaux (le fameux fondant au chocolat…hum), l’on ne sait quoi faire de nos 
                                        blancs d’œufs qui nous restent. Vous pouvez alors les utiliser pour vous faire 
                                        un masque visage maison très puissant!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- #2 -->
        <div v-if="id == 2">
            <div class="bg-cover bg-nature-drop-water bg-center">
                <div class="container mx-auto">
                    <div class="p-5 row">
                        <div class="col-12 flex flex-col items-start">
                            <h1 class="text-white text-3xl font-black font-epigrafica mt-2 uppercase ml-2">Lèvres sèches en temps d'harmattan ? Que faire ?</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="container mx-auto">
                    <div class="py-14 bg-cover row">
                        <div class="container mx-auto">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <q-img class="img-smooth-zoom" fit="cover" ratio="1" src="@/assets/media/experts-counselings/ibc-international-bio-cosmetics-conseil-experts-levres-seches.jpg" />
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-4xl font-black font-epigrafica mt-2 uppercase">Voici des méthodes naturelles simples pour soigner des lèvres gercées</h1>
                                    <p class="text-mineralgreen text-base mt-3">
                                        En période d'harmattan, les lèvres, très exposées, sont soumises à rudes épreuves. 
                                        Lorsqu’elles sont gercées, elles sont déshydratées et irritées. Craquelées, elles 
                                        sont parfois douloureuses au point qu’il est peu agréable de sourire. Heureusement, 
                                        il existe des astuces naturelles et efficaces pour soigner les lèvres gercées et une 
                                        solution toute simple pour prévenir ce désagrément.
                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <p class="text-mineralgreen text-base mb-5">
                                        Les ingrédients d’origine naturelle sont d’une grande efficacité pour garder sa bouche 
                                        bien hydratée et protéger les lèvres sans les abîmer pendant l'harmattan. 
                                        Voici quelques astuces faciles à réaliser:
                                    </p>
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica">Astuce 1 : Commencer par un gommage ultra doux</h1>
                                    <p class="text-mineralgreen text-base">
                                        Effectuer un gommage des lèvres gercées avec du miel additionné d’un peu de sucre en poudre. 
                                        Reste à frotter tout doucement avec une brosse à dent souple humidifiée avant de rincer 
                                        et d’essuyer. Les lèvres sont débarrassées des petites peaux, prêtes à recevoir un soin.
                                    </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica">Astuce 2 : Le miel pour un baume gourmand cicatrisant</h1>
                                    <p class="text-mineralgreen text-base">
                                        Pour soigner naturellement les lèvres gercées, il est vivement recommandé d’appliquer plusieurs fois 
                                        par jour un masque hautement réparateur : le miel. Appliqué sur les lèvres en couche généreuse, il a 
                                        besoin de quinze petites minutes de pause avant d’être retiré à l’aide d’un coton doux et non pelucheux 
                                        imbibé d’eau tiède. Reste à tamponner les lèvres pour les sécher sans les irriter. Le miel a un pouvoir 
                                        cicatrisant remarquable. Il permet donc de combler les petites crevasses si douloureuses. Le beurre de 
                                        karité pour une hydratation réparatrice
                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <p class="text-mineralgreen text-base">
                                        Tiré de la noix éponyme, le beurre de karité est réputé pour hydrater, adoucir, réparer. 
                                        Bourré de vitamines A et E, il est l’allié des lèvres gercées. L’idéal est d’enduire les lèvres 
                                        abîmées d’une couche épaisse de beurre de karité avant de se coucher puis de garder ce masque 
                                        salvateur jusqu’au matin. Dès la première application, les fonctions réparatrices du beurre de karité 
                                        vont commencer à faire leur effet. Ce masque réparateur pour les lèvres peut aussi être appliqué en journée
                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica">Astuce 3 : De l’huile pour soulager et protéger</h1>
                                    <p class="text-mineralgreen text-base">
                                        Effectuer un gommage des lèvres gercées avec du miel additionné d’un peu de sucre en poudre. 
                                        Reste à frotter tout doucement avec une brosse à dent souple humidifiée avant de rincer 
                                        et d’essuyer. Les lèvres sont débarrassées des petites peaux, prêtes à recevoir un soin.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- #3 -->
        <div v-if="id == 3">
            <div class="bg-cover bg-nature-drop-water bg-center">
                <div class="container mx-auto">
                    <div class="p-5 row">
                        <div class="col-12 flex flex-col items-start">
                            <h1 class="text-white text-3xl font-black font-epigrafica mt-2 uppercase ml-2">Astuces naturelles pour atténuer les cernes</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="container mx-auto">
                    <div class="py-14 bg-cover row">
                        <div class="container mx-auto">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <q-img class="img-smooth-zoom" fit="cover" ratio="1" src="@/assets/media/experts-counselings/ibc-international-bio-cosmetics-conseil-experts-cernes.jpg" />
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-4xl font-black font-epigrafica mt-2 uppercase">Les cernes ne sont jamais les bienvenues sur un visage ! Agissez maintenant !</h1>
                                    <p class="text-mineralgreen text-base mt-3">
                                        Qu’elles soient héréditaires, causées par la fatigue, le stress ou encore un déséquilibre hormonal, 
                                        les cernes ne sont jamais les bienvenues sur un visage ! En plus de donner l’air malade et fatigué, 
                                        elles sont particulièrement coriaces et s’avèrent compliquées à déloger. Pourtant, avec un peu de 
                                        patience et quelques remèdes de grand-mères, il est tout à fait possible de les atténuer, voire de 
                                        les éliminer. Tout de suite, voici donc 4 astuces naturelles contre les cernes!
                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica">Astuce 1 : Deux cuillères froides</h1>
                                    <p class="text-mineralgreen text-base">
                                        Placez deux petites cuillères au frais pendant 15 à 20 minutes. Appliquez-les ensuite 
                                        sur vos yeux pendant 10 minutes maxi : le froid va activer la circulation sanguine et 
                                        réveiller le regard. Attention toutefois à ne pas recourir à cette astuce trop souvent. 
                                        En effet, les vaisseaux sanguins du contour de l’œil sont très fragiles et risqueraient d’en souffrir.
                                    </p>
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-5">Astuce 2 : Rondelles de concombre ou de pomme de terre</h1>
                                    <p class="text-mineralgreen text-base">
                                        Coupez deux rondelles de concombre ou de pomme de terre et placez-en une sur chaque 
                                        œil avant d’aller vous coucher. Recommencez chaque soir pendant plusieurs semaines 
                                        en veillant à garder les légumes au frais pour plus d’efficacité.
                                    </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica">Astuce 3 : Bicarbonate et eau florale de rose</h1>
                                    <p class="text-mineralgreen text-base">
                                        Mélangez du bicarbonate de soude et de l’eau de rose jusqu’à obtenir une pâte. Appliquez-en sur 
                                        le pourtour de vos yeux et laissez poser 15 min avant de rincer à l’eau claire. Émollient et 
                                        blanchissant, le bicarbonate va permettre d’assouplir et d’éclaircir la peau. Quant à l’eau de rose, 
                                        elle va venir la décongestionner et la tonifier. Vous pouvez remplacer l’eau de rose par de 
                                        l’eau de bleuet, très efficace également dans le traitement des yeux gonflés et cerné
                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica">Astuce 4 : Lotion maison au miel</h1>
                                    <p class="text-mineralgreen text-base">
                                        Hydratant, anti-inflammatoire et apaisant, le miel est l’aliment idéal pour atténuer les cernes tenaces. 
                                        Pour l’utiliser, diluez-en 1 c. à c. dans 1 demi-verre d’eau tiède. Imbibez deux cotons ou compresses 
                                        de cette solution et posez-les sur vos yeux pendant 5 à 10 minutes. Rincez ensuite à l’eau claire et 
                                        recommencez l’opération pendant plusieurs jours.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- #4 -->
        <div v-if="id == 4">
            <div class="bg-cover bg-nature-drop-water bg-center">
                <div class="container mx-auto">
                    <div class="p-5 row">
                        <div class="col-12 flex flex-col items-start">
                            <h1 class="text-white text-3xl font-black font-epigrafica mt-2 uppercase ml-2">Les bienfaits multiples de l'huile de sesame</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="container mx-auto">
                    <div class="py-14 bg-cover row">
                        <div class="container mx-auto">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <q-img class="img-smooth-zoom" fit="cover" ratio="1" src="@/assets/media/experts-counselings/ibc-international-bio-cosmetics-conseil-experts-huile-de-sesame.jpg" />
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-4xl font-black font-epigrafica mt-2 uppercase">Ses nutriments en font une alliée à la fois sur les fourneaux et dans la salle de bain.</h1>
                                    <p class="text-mineralgreen text-base mt-3">
                                        L’huile de sésame pénètre rapidement sous la peau et la nourrit en profondeur. 
                                        Elle fait donc une excellente huile de massage qui permet de maintenir la peau 
                                        souple et élastique, de la protéger des microbes et d’accélérer la cicatrisation.
                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <p class="text-mineralgreen text-base">
                                        Pour vous offrir un moment de bien-être avant d’aller vous coucher, appliquez 
                                        une cuillerée à café d’huile de sésame sur vos pieds, massez, mettez des chaussettes 
                                        pour ne pas salir vos draps et profitez de votre nuit !
                                    </p>
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-5">Les bienfaits de l'huile de sésame pour les cheveux</h1>
                                    <p class="text-mineralgreen text-base">
                                        Pour faire briller votre chevelure sans utiliser de produits chimiques, 
                                        optez pour l’huile de sésame. Ses nutriments essentiels vont protéger 
                                        la fibre capillaire et le cuir chevelu, limitant ainsi 
                                        l’apparition de pellicules et la perte de cheveux.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- FOOTER CONTENT -->
        <!-- footer -->
        <footerbar></footerbar>
        
    </q-layout>
</template>

<script>
import navbar from "@/views/shared/Navbar"
import footerbar from "@/views/shared/Footer"
import { useMeta } from "quasar"

export default {
    name: "ExpertCounseling",
    components: { navbar, footerbar },
    data() {
        return {}
    },
    computed: {
        id () { return this.$route.params.id },
    },
    created () {
        useMeta({
            title: 'International Bio Cosmetics | Conseil #' + this.$route.params.id, // sets document title
        })
    }
};
</script>